import React from 'react';
import { navigateTo } from 'gatsby-link';

import './ContactForm.scss';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigateTo(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  render() {
    const formTitle = this.props.formTitle;

    return (
      <form
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        <h2>{formTitle}</h2>
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <label>
            Don't fill this out: <input name="bot-field" onChange={this.handleChange} />
          </label>
        </div>
        <div id="name">
          <label htmlFor="first-name">Name</label>
          <input id="first-name" type="text" name="name" onChange={this.handleChange} />
        </div>
        <div id="email">
          <label htmlFor="email-text">Email</label>
          <input id="email-input" type="email" name="email" onChange={this.handleChange} />
        </div>

        <div id="message">
          <label htmlFor="message-text">Message</label>
          <textarea id="message-text" name="message" onChange={this.handleChange} />
        </div>
        <div id="submitButton">
          <button type="submit">send message</button>
        </div>
      </form>
    );
  }
}
